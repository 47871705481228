import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Label from './ui-components/Label';
import Button from './ui-components/Button';
import Input from './ui-components/Input';
import './App.css';
import RangeSlider from './ui-components/RangeSlider';

import SearchSection from './pages/SearchSection';
import SearchedSection from './pages/SearchedSection';
import BannerSection from './pages/BannerSection';
import ArticleBlogs from './pages/ArticlesBlogs';
import FaqSection from './pages/FaqSection';

import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App h-full">
        <Header />

        <div className="flex flex-col">
          <SearchSection/>

          <div className = "flex flex-row">
            <ArticleBlogs/>
            <div className = "flex flex-col">
              <div className="flex flex-col items-start gap-10 mx-auto mt-10 px-20 py-10 rounded-sm border-2 border-white/12 bg-[#00323D] w-2/3">
                  <div className="text-white font-medium text-2xl leading-6 tracking-[0.4px] text-center w-full">
                      Why to use a barrister and not a solicitor?
                  </div>
                  <ul className="list-disc text-[#ADF1E8] font-medium text-base leading-6 tracking-[0.4px] text-left self-stretch">
                      <li className="mb-[20px] self-stretch"><span className="text-[#CCE0DD]">Barristers are like specialists</span> in the legal field, similar to consultants in healthcare. They have deep knowledge in specific areas of law and can offer clear advice on your situation, helping you understand your legal position and available options.</li>

                      <li className="mb-[20px] self-stretch">Barristers are known for <span className="text-[#CCE0DD]">resolving legal matters swiftly and providing prompt feedback,</span> so you won’t need to keep following up on your case.</li>

                      <li className="mb-[20px] self-stretch">Hiring a barrister doesn’t necessarily mean you’ll have to go to court, but if your case does require it, barristers are <span className="text-[#CCE0DD]">trained experts in advocacy and will present your case effectively.</span></li>

                      <li className="mb-[20px] self-stretch">Barristers often offer <span className="text-[#CCE0DD]">more affordable rates </span>than solicitors since they operate independently and don’t carry the overhead of a law firm. They will also <span className="text-[#CCE0DD]">agree on their fees upfront,</span> so you know the cost before proceeding.</li>
                  </ul>
              </div>
              <SearchedSection/>
              <FaqSection/>
            </div>
          </div>
          {/* <BannerSection/>
          <SearchedSection/>
          
          <FaqSection/> */}
        </div>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
