import React from 'react';
import SearchBar from '../components/SearchBar';
import Input from '../ui-components/Input';
import RangeSlider from '../ui-components/RangeSlider';
import ListWithSVG from '../ui-components/ListWithSVG';
import { Barrister } from '../assets/barrister';
import ComboBox from '../ui-components/ComboBox';

const itemList = [
    'Legal advice',
    'Conciliation',
    'Probate',
    'Document review',
    'Representation',
];


function SearchSection() {
    const renderLabel = (value) => {
        return `${value}%`;
      };

  return (
    <div className="w-full flex flex-row pt-[40px] gap-10">
        <div class="flex flex-col w-[435px] h-[902px] p-[40px_40px_40px_50px] items-start gap-[10px] flex-shrink-0 rounded-r-[20px] bg-[#A4E0D8] shadow-[0_2px_6px_0_rgba(0,0,0,0.12)]">
            <img className = "w-[345px] h-[249px] flex-shrink-0 rounded-[20px]" src={Barrister.Back}/>
            <p class="text-[#25395C] text-base font-normal leading-[1.25] tracking-[0.4px] self-stretch text-left">
                If you need someone to represent you in court or provide expert legal advice, a barrister is the professional you would seek.
            </p>
            <p class="text-[#25395C] text-base font-normal leading-[1.25] tracking-[0.4px] self-stretch text-left mb-[20px]">
                They are experts in courtroom advocacy, presenting cases before judges, and providing legal advice on complex matters. Barristers are often called upon when a case goes to trial or needs specialised legal opinions.
            </p>
            <p class="text-[#001A3D] text-lg font-bold leading-[1.3333] tracking-[0.4px]">
                What should you do?
            </p>
            <p class="text-[#25395C] text-base font-normal leading-[1.25] tracking-[0.4px] self-stretch text-left mb-[20px]">
                You can apply all the filters together or just one. Be sure to choose the ones that best match your needs.
            </p>
            <SearchBar/>
        </div>
        <div className = "flex flex-col items-center mx-auto">
            <div className="w-full flex flex-row items-center py-5 ">
                <p class="text-[#001A3D] text-2xl font-dm-sans font-bold leading-6 tracking-[0.4px] break-words">
                    Combine the filters to get the professional tailored to your needs
                </p>
                <div className="ml-auto flex flex-wrap">
                    
                </div>
            </div>

            <div className = "flex flex-col">
                <textarea 
                class="flex w-[736px] h-[355px] p-[15px] px-[20px] items-start gap-[30px] flex-shrink-0 rounded-[20px] border border-[#A0ABBE] shadow-[0px_-0.2px_30px_rgba(190,190,223,0.5),0px_0.2px_30px_rgba(190,190,223,0.5)] resize-none"
                placeholder="Type in your need or what you are searching for.."
                ></textarea>
            </div>

            <div className = "flex flex-row items-start gap-[70px] pt-[40px] mr-auto">
                <RangeSlider
                    min={0}
                    max={1000}
                    step={10}
                    renderLabel={'£'}
                    title="Rate per hour(ex VAT)"
                />
                <RangeSlider
                    min={0}
                    max={40}
                    step={10}
                    renderLabel={'years'}
                    title="Experience (years)"
                />
                <RangeSlider
                    min={0}
                    max={1000}
                    step={10}
                    renderLabel={'mi'}
                    title="Location radius"
                />
            </div>
            <div className = "mr-auto">
                <ComboBox/>
            </div>
            <div className = "flex flex-row py-[41px]">
                <span class="text-[#6E7785] font-dm-sans text-base font-medium leading-6 tracking-[0.4px]">
                    Need help finding a legal document?
                </span>
                <div class="flex justify-center items-center gap-2">
                    <a>Click here</a>
                </div>
            </div>
        </div>
        <div className = "ml-auto flex flex-col w-full mr-[20px] w-[450px]">
            <img src = {Barrister.Corner} className = "w-[417px] h-[388px] ml-auto"/>
            <div className = "absolute mt-20 mr-10">
                <div className="flex flex-col w-[375px] p-[30px] items-start flex-shrink-0 rounded-[16px] border-[1.4px] border-[#A4E0D8] bg-white shadow-[0px_2px_20px_0px_rgba(187,199,215,0.2)]">
                    <span class="text-[#6E7785] text-center font-dm-sans text-base font-medium leading-6 tracking-[0.4px] pb-[28px]">
                        Advanced search
                    </span>
                    <div className = "flex flex-row items-center justify-center gap-4 w-full py-[28px]">
                        <div class="flex w-[30px] h-[30px] flex-col justify-center items-center gap-[10px] rounded-full bg-[#A4E0D8]">1</div>
                        <div class="flex w-[30px] h-[30px] flex-col justify-center items-center gap-[10px] rounded-full bg-[#D9E0DF]">2</div>
                        <div class="flex w-[30px] h-[30px] flex-col justify-center items-center gap-[10px] rounded-full bg-[#D9E0DF]">3</div>
                    </div>
                    <span class="text-[#25395C] font-dm-sans text-base font-medium leading-6 tracking-[0.4px]">
                        What service do you need?
                    </span>
                    <ListWithSVG></ListWithSVG>

                    <button className="ml-auto mt-[28px] flex items-end justify-end gap-2.5 h-8 px-3 py-1.5 rounded-lg bg-[#F4F4F4] shadow-[0px_1px_4px_rgba(0,0,0,0.25)] text-[#A0ABBE] text-center font-dm-sans text-base font-medium leading-5 tracking-[0.4px]">
                        <span>Continue</span>
                    </button>
                </div>
                
                <p className="text-[#6E7785] text-center font-dm-sans text-base font-normal leading-5 tracking-[0.4px] mt-[8px]">
                    Use our advanced search to guide you through three steps and find the lawyer best suited to your legal needs.
                </p>
                
                <div className = "flex flex-row mt-10">
                    <button className="ml-auto flex items-center gap-6 text-[#001A3D] text-center font-dm-sans text-base font-medium leading-6 tracking-[0.4px]">
                        Clear filters
                    </button>
                    <button className="ml-auto flex h-[44px] px-[16px] py-[12px] justify-center items-center gap-[10px] rounded-[8px] bg-[#020251] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] text-[#FFF] text-center font-dm-sans text-base font-medium leading-5 tracking-[0.4px] mr-20">
                        Check results
                    </button>
                </div>
            </div>
        </div>
    </div>
   );
}

export default SearchSection;